var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormLabel, FormGroup, FormControl, FormHelperText, FormControlLabel, Tooltip, formControlLabelClasses, } from '@mui/material';
// translation
import { useLocales } from '../../locales';
export function RHFCheckbox(_a) {
    var name = _a.name, helperText = _a.helperText, label = _a.label, other = __rest(_a, ["name", "helperText", "label"]);
    var control = useFormContext().control;
    var translate = useLocales().translate;
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs(_Fragment, { children: [_jsx(Tooltip, __assign({ title: "".concat(translate(helperText)) || "".concat(translate(name)) }, { children: _jsx(FormControlLabel, __assign({ label: "".concat(translate(label)), control: _jsx(Checkbox, __assign({}, field, { checked: field.value })) }, other)) })), (!!error || helperText) && (_jsx(FormHelperText, __assign({ error: !!error }, { children: error ? "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) : "".concat(translate(helperText)) })))] }));
        } }));
}
export function RHFMultiCheckbox(_a) {
    var row = _a.row, name = _a.name, label = _a.label, options = _a.options, spacing = _a.spacing, helperText = _a.helperText, sx = _a.sx, other = __rest(_a, ["row", "name", "label", "options", "spacing", "helperText", "sx"]);
    var control = useFormContext().control;
    var getSelected = function (selectedItems, item) {
        return (selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.includes(item))
            ? selectedItems.filter(function (value) { return value !== item; })
            : __spreadArray(__spreadArray([], selectedItems, true), [item], false);
    };
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var _b;
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs(FormControl, __assign({ component: "fieldset" }, { children: [label && (_jsx(FormLabel, __assign({ component: "legend", sx: { typography: 'body2' } }, { children: label }))), _jsx(FormGroup, __assign({ sx: __assign(__assign(__assign({}, (row && {
                            flexDirection: 'row',
                        })), (_b = {}, _b["& .".concat(formControlLabelClasses.root)] = __assign({ '&:not(:last-of-type)': {
                                mb: spacing || 0,
                            } }, (row && {
                            mr: 0,
                            '&:not(:last-of-type)': {
                                mr: spacing || 2,
                            },
                        })), _b)), sx) }, { children: options.map(function (option) {
                            var _a;
                            return (_jsx(FormControlLabel, __assign({ control: _jsx(Checkbox, { checked: (_a = field === null || field === void 0 ? void 0 : field.value) === null || _a === void 0 ? void 0 : _a.includes(option.value), onChange: function () { return field.onChange(getSelected(field.value || [], option.value)); } }), label: option.label }, other), option.value));
                        }) })), (!!error || helperText) && (_jsx(FormHelperText, __assign({ error: !!error, sx: { mx: 0 } }, { children: error ? error === null || error === void 0 ? void 0 : error.message : helperText })))] })));
        } }));
}
